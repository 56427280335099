<template>
<!-- 新闻资讯-->
<div>
    <div class="articleListBox" id="scroll">
        <!-- :style="{height:bannerHeight+'rem'}" -->
        <div class="topcon" >
            <!-- <img src="https://images.innocomn.com/5c4a420210226172650727.jpg?imageView2/2/w/4096/q/100!|imageslim"> -->
            <img :src="kvimg" v-if="kvimg">
        </div>
        <div class="topTitle">
            <div class="titlecon">
            <img src="../../assets/otherTU/articleicon.png">
            <span>文章列表</span>
            </div>
        </div>
        <div class="articleCenter">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
            <van-empty :image="require('@/assets/null.svg')" v-else-if="listData.length == 0"/>
             <van-list v-model="celloading" :finished="finished" finished-text="没有更多了"  @load="onLoad" v-if="listData.length>0">
                <van-cell v-for="(item,index) in listData" :key="index" class="conBox"  @click="articleDetails(item.id)">
                    <div class="center">
                        <img :src="item.thumbnail_url" alt="">
                        <div class="conright">
                            <p>{{item.new_title}}</p>
                            <span>{{item.abstract}}</span>
                            <div class="centertime flex"><span>{{item.updated_at}}</span><div class="flex"><van-icon name="eye-o" style="font-size:.15rem;"/>&nbsp;{{item.views_count}}</div></div>
                        </div>
                    </div>
                </van-cell>
             </van-list>
        </div>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
</template>
 
<script>
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
        Support
    },
    data(){
        return{
            bannerHeight:null,
            listData:[],
            params:{},
            kvimg:'',
            Loading:true,
            site_id:'',
            isgoBack:false,
            page:1,
            page_num:20,
            celloading:false,
            finished: false,//下拉加载是否是最后
        }
    },
    created(){
        this.params = this.$route.query;
    },
    mounted(){
        let width = document.body.clientWidth;
        this.bannerHeight = (width / 16 * 9)/100;
        if(this.params.module_id){
            this.news_information_list()
        }else{
            
            if(this.$route.params.site_id){
                sessionStorage.setItem('site_id', JSON.stringify(this.$route.params.site_id))
                sessionStorage.setItem('show_grouping', JSON.stringify(this.$route.params.show_grouping))
                this.site_id = this.$route.params.site_id
                this.show_grouping = this.$route.params.show_grouping
                this.showroom_news_list()
            }else{
                this.isgoBack = true
                this.site_id = JSON.parse(sessionStorage.getItem('site_id'))
                this.show_grouping = JSON.parse(sessionStorage.getItem('show_grouping'))
                this.showroom_news_list()
            }
        }
    },
    computed: {
        ...mapState(['themecolor', 'themecolors'])
    },
    methods:{
        articleDetails(id){
            let params = ''
            if(this.params.module_id){
                if(this.$route.query.singePage == 'singePage'){
                    params = {
                        singePage: 'singePage',
                        backtrack: 'backtrack',
                        site_url: this.$route.query.site_url,
                        site_id:this.params.site_id,
                        module_id:this.params.module_id,
                        id: id,
                    }
                } else {
                    params = {
                        site_id:this.params.site_id,
                        module_id:this.params.module_id,
                        id: id,
                    }
                }
            }else if(this.$route.params.site_id){
                if(this.$route.query.singePage == 'singePage'){
                    params = {
                        singePage: 'singePage',
                        backtrack: 'backtrack',
                        site_url:this.$route.query.site_url,
                        site_id:this.site_id,
                        show_grouping:this.show_grouping,
                        id: id,
                    }
                } else {
                    params = {
                        site_id:this.site_id,
                        show_grouping:this.show_grouping,
                        id: id,
                    }
                }
            }else if(this.isgoBack){
                if(this.$route.query.singePage == 'singePage'){
                    params = {
                        singePage: 'singePage',
                        backtrack: 'backtrack',
                        site_url:this.$route.query.site_url,
                        site_id:this.site_id,
                        show_grouping:this.show_grouping,
                        id: id,
                    }
                } else {
                    params = {
                        site_id:this.site_id,
                        show_grouping:this.show_grouping,
                        id: id,
                    }
                }
            }
            this.$router.push({name:"articleDetails",query:params});
        },
        // 下拉加载数据
        onLoad(){
            this.page++
            if(this.params.module_id){
                this.news_information_list()
            }else{
                this.Loading=false
                 this.finished = true;
            }
            
        },
        // 展厅展示新闻资讯列表
        showroom_news_list(){
            this.$store.dispatch("showroom_news_list",{
                site_id:this.site_id,
                show_grouping:this.show_grouping
            }).then(res=>{
                if(res.data.code === 200){
                    document.title = res.data.data.data.module_title
                    this.kvimg = res.data.data.list_kv
                    this.listData = res.data.data.data.module_content
                }
            })
        },
        news_information_list(){
            this.$store.dispatch("news_information_list",{
                site_id:this.params.site_id,
                module_id:this.params.module_id,
                page:this.page,
                page_num:this.page_num,
            }).then(res=>{
                if(res.data.code == 200){
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                    this.listData = [...this.listData,...res.data.data.module_content.data]
                    this.kvimg = res.data.data.list_kv
                    this.celloading = false;
                    if(res.data.data.module_content.current_page>=res.data.data.module_content.last_page){
                        this.finished = true;
                    } 
                    this.Loading=false
                    // this.listData.forEach(item => {
                    //     item.updated_at = this.timestampToTime(item.updated_at)
                    // });
                }
            })
        },
        timestampToTime (time) {
            var date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d + ' ' + h + ":" + m + ":"+ s
        }
    },
}
</script>

<style scoped lang="less">
    /deep/.van-empty,/deep/.van-loading{
        position: absolute;
        top: 42%;
        left:50%;
        transform:translateX(-50%);
    }
    /deep/.van-empty{
        width: 100%;
        .van-empty__image{
            width: 3rem;
            height: 3rem;
        }
    }
    /deep/.van-loading {
        top: 65%;
    }
    .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .articleListBox::-webkit-scrollbar{
        display: none;
    }
    .articleListBox{
        width: 100%;
        height: 100%;
        overflow: scroll;
        background: #fff;
        .topcon{
            width: 100%;
            height: 2.1rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .topTitle{
            width: 100%;
            height: .44rem;
            background: #FFFFFF;
            box-shadow: 0 .03rem .08rem #EEEEEE;
            .titlecon{
                width: 90%;
                height: 100%;
                margin:0 auto;
                display: flex;
                align-items: center;
                img{
                    width: .27rem;
                }
                span{
                    font-size: .14rem;
                    display: block;
                    font-weight: bold;
                    line-height: .44rem;
                    margin-left: .1rem;

                }
            }
        }
        .articleCenter{
            width: 100%;
            // box-shadow: 0px 3px 8px #EEEEEE;
            min-height: 55%;
            margin-top: .1rem;
            .van-list{
                /deep/.van-list__loading{
                    .van-loading{
                        position: inherit;
                        transform:translateX(0);
                    }
                }
                
            }
            .conBox{
                display: flex;
                width: 94%;
                background: #ffffff;
                margin: 0 auto;
                .center{
                    width: 100%;
                    height: .8rem;
                    display: flex;
                    justify-content: space-around;
                    margin-top: .01rem;
                    img{
                        width:1.2rem;
                        height: .7rem;
                        border-radius: .03rem;
                        object-fit: contain;
                        background: #ccc;
                    }
                    .conright{
                        width: calc(100% - 1.5rem);
                        font-size: .14rem;
                        p{
                           color: #333333;
                           font-weight: bold; 
                           overflow: hidden;
                           white-space: nowrap;
                           text-overflow: ellipsis;
                           line-height: .25rem;
                           height: .25rem;
                        }
                        span{
                            color: #646566;
                            line-height: .25rem;
                            height: .25rem;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            display: block;
                        }
                        .centertime{
                           color: #969799; 
                           font-size: .12rem;
                           line-height: .2rem;
                           height: .2rem;
                        }
                    }
                }
            }
        }
        .suspend-btn{
            position: fixed;
            bottom: 15%;
            right: 3%;
            div{
                width: .5rem;
                height: .5rem;
                background: rgba(0, 204, 153, .8);
                border-radius: 50%;
            }
            .back-top{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: .26rem;
                }
            }
            .home-box{
                margin-top: .1rem;
                padding-top: .06rem;
                line-height: .15rem;
                font-size: .12rem;
                color: #fff;
                img{
                    width: .2rem;
                    margin-left: .15rem;
                }
                p{
                    text-align: center;
                }
            }
        }
        // /deep/.support{
        //     color: #969799;
        //     margin-top: .3rem;
        // }
    }
</style>